import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';

const AuthorizeRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    // Construct the redirect URI based on the device type
    const redirectURI = isDesktop 
      ? `https://app.ybe.life/settings${location.search}` 
      : `com.bnc.nfcmatch://authorize${location.search}`;

    // Perform the redirect
    window.location.href = redirectURI;
  }, [location.search]);

  // Render a fallback view (in case the redirect is delayed)
  return <div>Redirecting...</div>;
}

export default AuthorizeRedirect;
