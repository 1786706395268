import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isIOS, isAndroid } from 'react-device-detect';

const VibeRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    // Extract the 'id' query parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    let redirectURI;

    if (isIOS) {
      // Redirect to the App Store
      redirectURI = 'https://apps.apple.com/us/app/ybe-your-vibe-check/id6449936313';
    } else if (isAndroid) {
      // Redirect to the Google Play Store
      redirectURI = 'https://play.google.com/store/apps/details?id=com.bnc.nfcmatch';
    } else {
      // Redirect desktop users to the desktop URL
      redirectURI = `https://app.ybe.life/vibes/${id}`;
    }

    // Perform the redirect
    window.location.href = redirectURI;
  }, [location.search]);

  // Render a fallback view (in case the redirect is delayed)
  return <div>Redirecting...</div>;
}

export default VibeRedirect;
