// import React from 'react';

const RedirectApp = () => {
  // Determine the user agent
  const userAgent = navigator.userAgent || navigator.vendor;

  // Check the platform and redirect accordingly
  if (/android/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.bnc.nfcmatch';  // Your app link on Google Play
    return null;
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    window.location.href = 'https://apps.apple.com/us/app/ybe-your-vibe-check/id6449936313';  // Your app link on the App Store
    return null;
  } else {
        window.location.href = 'https://activate.ybe.life/start';  // Your app link on the App Store
    return null;
  }

  // If it's neither Android nor iOS, display QR code
  // return (
  //   <div style={{display: 'flex',
  //               flexDirection: 'column',
  //               justifyContent: 'center',
  //               alignItems: 'center',
  //               height: '100vh'}}>
  //     <h1>Scan this QR code with your phone to download</h1>
  //     <img src={"qr.png"} alt="QR Code" style={{ width: '250px', height: '250px' }}/>
  //   </div>
  // );
}

export default RedirectApp;
